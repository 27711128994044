import { Component, OnInit } from '@angular/core';
import { CryptosService } from 'src/app/servicios/crypto/cryptos.service';

@Component({
  selector: 'app-private-page',
  templateUrl: './private-page.component.html',
  styleUrls: ['./private-page.component.css']
})
export class PrivatePageComponent implements OnInit {
  items: Item[];

  constructor(
    private _cryptosService: CryptosService,
  ) { }

  ngOnInit() {
    // this._cryptosService.getCrypto().subscribe(res => this.items = res);
  }

}

interface Item {

  // css-mzoqhr : string;
  title : string;
  url : string;

  }
