import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class CryptosService {

  result:any;

  constructor(private _http: Http) { }

  getCrypto() {
    return this._http.get("https://coinmarketcap.com/es/currencies/decentraland/")
      .map(result => this.result = result.json().data);
  }


}
