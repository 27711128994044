export class Cliente {
  $key: string;
  rut: string;
  nombre: string;
  apellidos: string;
  telefono: string;
  email: string;
  pagina: string;
  estado: string;
  fechaRenovacion: string;
  fechaRenoClass: string;
}
