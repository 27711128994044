import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { NavbarComponent } from './componentes/navbar/navbar.component';
import { LoginComponent } from './componentes/login/login.component';
import { PrivatePageComponent } from './componentes/private-page/private-page.component';
import { NotFoundPageComponent } from './componentes/not-found-page/not-found-page.component';
import { AppRoutingModule } from './/app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';


// Servicios
import { AuthService } from './servicios/auth.service';


// Firebase
import {AngularFireModule} from 'angularfire2';
import {AngularFireAuthModule} from 'angularfire2/auth';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireStorageModule } from 'angularfire2/storage';

// Mensajeria
import { FlashMessagesModule, FlashMessagesService } from 'angular2-flash-messages';

// Filtros
import { SearchPipe } from './pipes/search.pipe';
import { SearchNombrePipe } from './pipes/search-nombre.pipe';

import { AuthGuard } from 'src/app/guards/auth.guard';

// Componentes
import { ClientesComponent } from './componentes/clientes/clientes.component';
import { ClienteComponent } from './componentes/clientes/cliente/cliente.component';
import { ClientesListaComponent } from './componentes/clientes/clientes-lista/clientes-lista.component';
import { ListaPagosComponent } from './componentes/pagos/lista-pagos/lista-pagos.component';
import { PagosIdClienteComponent } from './componentes/pagos/pagos-id-cliente/pagos-id-cliente.component';
import { PagosFormComponent } from './componentes/pagos/pagos-form/pagos-form.component';
import { FooterComponent } from './componentes/footer/footer.component';

// ngx-bootstrap
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale'; // esLocale para español
// import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgxPaginationModule } from 'ngx-pagination';
defineLocale('es', esLocale);
import { from } from 'rxjs';
import { ModalModule } from 'ngx-bootstrap/modal';

import { TextMaskModule } from 'angular2-text-mask';
import { CryptosService } from './servicios/crypto/cryptos.service';
import { Http, HttpModule } from '@angular/http';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LoginComponent,
    PrivatePageComponent,
    NotFoundPageComponent,
    ClientesComponent,
    ClienteComponent,
    ClientesListaComponent,
    ListaPagosComponent,
    PagosIdClienteComponent,
    PagosFormComponent,
    SearchPipe,
    SearchNombrePipe,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    // Http,
    HttpModule,
    FormsModule,
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    FlashMessagesModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      toastClass: 'toast toast-bootstrap-compatibility-fix',
      // timeOut: 10000,
      // positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }), BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    NgxPaginationModule,
    // PaginationModule.forRoot()
    ModalModule.forRoot(),
    TextMaskModule,
  ],
  providers: [AuthService, AuthGuard, FlashMessagesService, CryptosService],
  bootstrap: [AppComponent]
})
export class AppModule { }
