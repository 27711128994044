import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';

import { ClienteService } from 'src/app/servicios/cliente/cliente.service';
import { Cliente } from 'src/app/models/cliente';

import { ToastrService } from 'ngx-toastr';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import StringUtils from 'src/app/utils/string-utils';

@Component({
  selector: 'app-clientes-lista',
  templateUrl: './clientes-lista.component.html',
  styleUrls: ['./clientes-lista.component.css']
})
export class ClientesListaComponent implements OnInit  {

  datePipe = new DatePipe('en-US');

  // Facha Actual
  hoy = new Date();

  // Filtro Busqueda
  email = '';
  nombre = '';

  // Paginacion
  // currentPage = 1;
  // totalItems: any;
  // page: number;

  // ngx-pagination
  itemsPerPage = 3;
  pageActual = 1;

  clienteList: Cliente[];

  // jQuery: any;

  constructor(
    public clienteService: ClienteService,
    public toastrService: ToastrService,
    private elementRef: ElementRef,
    public flasMensaje: FlashMessagesService,
    public router: Router
  ) { }

  ngOnInit() {
    // this.ngAfterViewInit();

    this.clienteService.getClientes()
    .snapshotChanges() // Obtine todos los cambios desde la BD
    .subscribe(item => { // Trae los datos insetados
      this.clienteList = [];
      item.forEach(element => {
        // this.totalItems ++;
        const x = element.payload.toJSON(); // El contenido del elemento lo convierte  Json
        x['$key'] = element.key;
        x['fechaRenoClass'] = StringUtils.validarFechaAddClass(x['fechaRenovacion'], this.hoy);
        this.clienteList.push(x as Cliente);
      });
    });

  }

  // pageChanged(event: any): void {
  //   this.page = event.page;
  // }

  onEdit(cliente: Cliente) {
    // Mostrar
    this.router.navigate(['/editCliente']);
    this.clienteService.selectedCliente = Object.assign({}, cliente);
  }

  onPagos(cliente: Cliente) {
    // Mostrar [routerLink]="['/pagosCliente', cliente.rut]"
    this.router.navigate(['/pagosCliente', cliente.rut]);
    this.clienteService.selectedCliente = Object.assign({}, cliente);
  }

  onDelete($key: string) {
    if (confirm('Seguro que desea eliminar el cliente')) {
      this.clienteService.deleteCliente($key);
      this.toastrService.success('Operacion exitosa', 'Cliente Eliminado');
      this.flasMensaje.show('Cliente Eliminado Exitosamente', {cssClass: 'alert-success', timeout: 4000});
    }
  }

}
