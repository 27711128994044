import { Component, OnInit, TemplateRef, ElementRef, ViewChild} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { PagosService } from 'src/app/servicios/pago/pagos.service';
import { ClienteService } from 'src/app/servicios/cliente/cliente.service';
import { NgForm } from '@angular/forms';
import { Pago } from 'src/app/models/pago';
import { AngularFireStorage } from 'angularfire2/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { listLocales } from 'ngx-bootstrap/chronos';
import { Cliente } from 'src/app/models/cliente';
import { async } from '@angular/core/testing';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const currencyMask = {
  prefix: '$ ',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2,
  requireDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: false,
  integerLimit: 8
};

@Component({
  selector: 'app-pagos-form',
  templateUrl: './pagos-form.component.html',
  styleUrls: ['./pagos-form.component.css']
})
export class PagosFormComponent implements OnInit {
  @ViewChild('comprobante') comprobante: ElementRef;
  // Configuracion fecha Rexpiracion y proximo pago
  anoRenovacion = 1;
  diasProximoPago = 5;

  // Modal
  modalRef: BsModalRef;

  // Imagen
  uploadPorcent: Observable<number>;
  urlImagen: Observable<string>;
  urlImagenString: string;
  isImagenDisabled: Boolean = true;

  // Calendario
  // pipe = new DatePipe('en-US');
  datePipe = new DatePipe('en-US');

  // theme-default, theme-green, theme-blue, theme-dark-blue, theme-red, theme-orange
  colorTheme = 'theme-dark-blue';
  locale = 'es';
  locales = listLocales();
  bsConfig: Partial<BsDatepickerConfig>;
  myDateValue: Date;

  // combo Clientes
  clienteList: Cliente[];
  rutCliente: any;

  rutClienteRoute: any;

  // Mascara
  currencyMask = createNumberMask({
    ...currencyMask
  });

  constructor(
    public clienteService: ClienteService,
    public pagosService: PagosService,
    private toastrService: ToastrService,
    public flasMensaje: FlashMessagesService,
    public router: Router,
    private route: ActivatedRoute,
    public angularFireStorage: AngularFireStorage,
    private modalService: BsModalService,
    private localeService: BsLocaleService,
  ) { }

  ngOnInit() {
    // Rut de cliente proveniente de listado cliente
    this.rutClienteRoute = this.route.snapshot.paramMap.get('rutCliente');

    // Configuracion color calendario
    this.localeService.use(this.locale);
    this.getFecha();
    this.bsConfig = Object.assign({}, {
      containerClass: this.colorTheme,
      dateInputFormat: 'DD-MM-YYYY'
      // customTodayClass: 'custom-today-class'
    });

    this.pagosService.getPagos();
    // this.cleanForm();

    // Cargar Combo Clientes
    this.cargarComboClientes();

    // Cargar Comprobante de pago seleccionado
    this.cargarImgSelectedPago();
    this.cargarClienteModificar();

  }



  cargarClienteModificar() {
    if  (null != this.pagosService.selectedPago.rutCliente) {
      this.isImagenDisabled = null;
    }
  }

  /**
   * Cargar Comprobante de pago seleccionado
   */
  cargarImgSelectedPago() {
      if  (null != this.pagosService.selectedPago.comprobante) {
        this.urlImagenString = this.pagosService.selectedPago.comprobante;
      }

  }

  /**
   * Obtener clientes para el combo
   */
  cargarComboClientes() {
    this.clienteService.getClientes()
    .snapshotChanges() // Obtine todos los cambios desde la BD
    .subscribe(item => { // Trae los datos insetados
      this.clienteList = [];
      item.forEach(element => {
        // this.totalItems ++;
        const x = element.payload.toJSON(); // El contenido del elemento lo convierte  Json
        x['$key'] = element.key;

        // Determina si carga todos los cliente o solo el clienteX = rutClienteRoute
        if (null != this.rutClienteRoute) {
          if (this.rutClienteRoute === x['rut']) {
            this.pagosService.selectedPago.rutCliente = this.rutClienteRoute;
            this.pagosService.selectedPago.nombreCliente = x['nombre'];
            this.clienteList.push(x as Cliente);
            // console.log('selectedCliente', this.clienteService.selectedCliente);
          }
        } else {
          this.clienteList.push(x as Cliente);
        }
      });
    });
  }

  /**
   * Selecionar cliente de combo
   * @param e
   * @param pagoForm
   */
  selecccionarCliente(e: any, pagoForm?: NgForm) {
    console.log(this.pagosService.selectedPago.nombreCliente);
    console.log(e.target.value);
    // const cliente = this.clienteList.filter(c => c.nombre === e.target.value);
    const cliente = this.clienteList.find(c => c.nombre === this.pagosService.selectedPago.nombreCliente);

    this.pagosService.selectedPago.rutCliente = cliente.rut;
    // pagoForm.value.rutCliente = cliente.rut;

    this.isImagenDisabled = null;
  }

  /**
   * Setear fechas de pago seleccionado o fecha actual, fechaExpiracion y fechaProximoPago
   */
  getFecha() {
    if (null != this.pagosService.selectedPago) {
      if  (null == this.pagosService.selectedPago.fechaInicio) {
        this.pagosService.selectedPago.fechaInicio = this.datePipe.transform(new Date(), 'dd-MM-yyyy');
      }

      // Fecha maxima Prorroga antes de  dar de baja servicio
      if  (null == this.pagosService.selectedPago.fechaExpiracion) {
        const fe = new Date();
        fe.setFullYear(fe.getFullYear() + this.anoRenovacion );
        this.pagosService.selectedPago.fechaExpiracion = this.datePipe.transform(fe, 'dd-MM-yyyy');
      }

      if  (null == this.pagosService.selectedPago.fechaProximoPago) {
        const fpp = new Date();
        fpp.setFullYear(fpp.getFullYear() + this.anoRenovacion );
        fpp.setDate(fpp.getDate() + this.diasProximoPago);
        this.pagosService.selectedPago.fechaProximoPago = this.datePipe.transform(fpp, 'dd-MM-yyyy');
      }
    } else {
      // fechaInicio
      this.pagosService.selectedPago.fechaInicio = this.datePipe.transform(new Date(), 'dd-MM-yyyy');

      // fechaExpiracion
      const fe = new Date();
      fe.setFullYear(fe.getFullYear() + this.anoRenovacion );
      this.pagosService.selectedPago.fechaExpiracion = this.datePipe.transform(fe, 'dd-MM-yyyy');

       // fechaProximoPago
      const fpp = new Date();
      fpp.setFullYear(fpp.getFullYear() + this.anoRenovacion );
      fpp.setDate(fpp.getDate() + this.diasProximoPago);
      this.pagosService.selectedPago.fechaProximoPago = this.datePipe.transform(fpp, 'dd-MM-yyyy');

    }
  }

  /**
   *  Rgistrar o Actualizar Pago
   * @param pagoForm
   */
  onSubmit(pagoForm: NgForm) {
    pagoForm.value.fechaInicio = this.datePipe.transform(pagoForm.value.fechaInicio, 'dd-MM-yyyy');
    pagoForm.value.fechaExpiracion = this.datePipe.transform(pagoForm.value.fechaExpiracion, 'dd-MM-yyyy');
    pagoForm.value.fechaProximoPago = this.datePipe.transform(pagoForm.value.fechaProximoPago, 'dd-MM-yyyy');

    this.pagosService.selectedPago.comprobante = this.comprobante.nativeElement.value;
    console.log(this.comprobante.nativeElement.value);

    pagoForm.value.comprobante = this.pagosService.selectedPago.comprobante;

    if (pagoForm.value.$key == null) {
      this.pagosService.insertPago(pagoForm.value);
      // this.toastrService.success('Operacion exitosa', 'Cliente Registrado');
      this.toastrService.success('This is success toast.', 'Success!');
      this.flasMensaje.show('Operacion exitosa', {cssClass: 'alert-success', timeout: 4000});
    } else {
      this.pagosService.updatePago(pagoForm.value);
      // this.toastrService.success('Operacion exitosa', 'Cliente Modificado');
      this.toastrService.success('This is success toast.', 'Success!');
      this.flasMensaje.show('Operacion exitosa', {cssClass: 'alert-success', timeout: 4000});
    }

    console.log('selectedCliente', this.clienteService.selectedCliente);

    this.clienteService.selectedCliente.fechaRenovacion = pagoForm.value.fechaExpiracion;
    this.clienteService.updateCliente(this.clienteService.selectedCliente);

    this.cancelar(pagoForm);
  }

  /**
   * Resetear selectedPago
   * @param pagoForm
   */
  cleanForm(pagoForm?: NgForm) {
    if (pagoForm != null) {
      pagoForm.reset();
      this.pagosService.selectedPago =  new Pago();
    }

    this.isImagenDisabled = true;
    this.uploadPorcent = null;
    this.urlImagen = null;
    this.urlImagenString = null;

    this.getFecha();
  }

  /**
   * Limpiar formulario y redireccionar a lista de pagos
   * @param pagoForm
   */
  cancelar(pagoForm?: NgForm) {
    this.cleanForm(pagoForm);
    this.router.navigate(['/pagos']);
  }

  /**
   * Subir imagen a Fiebase
   * @param e
   */
  onUpload(e: any) {
    console.log('Subir', e.target.files[0]);
    console.log('Tamaño', e.target.files[0].size);
    // generar numero aleatore
    const id = Math.random().toString(36).substring(2);
    const file = e.target.files[0];
    const fileName = this.pagosService.selectedPago.nombreCliente
      + '_' + this.pagosService.selectedPago.fechaInicio;

    // const filePath = 'upload/profile${id}';
    // const filePath = 'upload/image.jpg';
    const filePath = this.pagosService.selectedPago.rutCliente
      + '/' + fileName + '.jpg';

    const ref = this.angularFireStorage.ref(filePath);
    const task = this.angularFireStorage.upload(filePath, file);

    this.uploadPorcent = task.percentageChanges();
    task.snapshotChanges().pipe(finalize(() => this.urlImagen = ref.getDownloadURL())).subscribe();
  }

  /**
   * Abrir Modal Bootstrap
   * @param template
   */
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

}
