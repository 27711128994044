import { Injectable } from '@angular/core';
import { AngularFireList, AngularFireDatabase } from 'angularfire2/database';
import { Pago } from 'src/app/models/pago';

@Injectable({
  providedIn: 'root'
})
export class PagosService {

  public pagoList: AngularFireList<any>;
  selectedPago: Pago = new Pago();

  constructor(
    private firebase: AngularFireDatabase
  ) { }

  getPagos() {
    return this.pagoList = this.firebase.list('pago');
  }

  insertPago(pago: Pago) {
    this.pagoList.push({
      rutCliente: pago.rutCliente,
      nombreCliente: pago.nombreCliente,
      fechaInicio: pago.fechaInicio,
      fechaExpiracion: pago.fechaExpiracion,
      estadoPago: pago.estadoPago,
      montoPago: pago.montoPago,
      comprobante: pago.comprobante,
      fechaProximoPago: pago.fechaProximoPago
    });
  }

  updatePago(pago: Pago) {
    this.pagoList.update(pago.$key, {
      rutCliente: pago.rutCliente,
      nombreCliente: pago.nombreCliente,
      fechaInicio: pago.fechaInicio,
      fechaExpiracion: pago.fechaExpiracion,
      estadoPago: pago.estadoPago,
      montoPago: pago.montoPago,
      comprobante: pago.comprobante,
      fechaProximoPago: pago.fechaProximoPago
    });
  }

  deletePago($key: string) {
    this.pagoList.remove($key);
  }
}
