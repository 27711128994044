import { Component, OnInit } from '@angular/core';
import { ClienteService } from 'src/app/servicios/cliente/cliente.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Pago } from 'src/app/models/pago';
import { PagosService } from 'src/app/servicios/pago/pagos.service';
import StringUtils from 'src/app/utils/string-utils';

@Component({
  selector: 'app-lista-pagos',
  templateUrl: './lista-pagos.component.html',
  styleUrls: ['./lista-pagos.component.css']
})
export class ListaPagosComponent implements OnInit {

  // Facha Actual
  hoy = new Date();

  pagoList: Pago[] = [];

  rutClienteRoute: any;

  urlImagen: any;

  constructor(
    private pagosService: PagosService,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private flasMensaje: FlashMessagesService

  ) { }

  ngOnInit() {

    // Cargar Cliente
    this.getPagosClientes();

  }

  /**
   * Determina si debe cargar los pagos de XCliente o de todos
   */
  getPagosClientes() {
    // Rut de cliente proveniente de listado cliente
    this.rutClienteRoute = this.route.snapshot.paramMap.get('rutCliente');

    // Obtener pagos de todos los Clientes
    this.pagosService.getPagos()
    .snapshotChanges() // Obtine todos los cambios desde la BD
    .subscribe(item => { // Trae los datos insetados
      this.pagoList = [];
      item.forEach(element => {
        const x = element.payload.toJSON(); // El contenido del elemento lo convierte  Json
        x['$key'] = element.key;

        x['fechaExpiClass'] = StringUtils.validarFechaAddClass(x['fechaExpiracion'], this.hoy);
        x['fechaProxiClass'] = StringUtils.validarFechaAddClass(x['fechaProximoPago'], this.hoy);

        // Determina si carga todos lo pagos o los pago de clienteX = rutClienteRoute
        if (null != this.rutClienteRoute) {
          if (this.rutClienteRoute === x['rutCliente']) {
            this.pagoList.push(x as Pago);
          }
        } else {
          this.pagoList.push(x as Pago);
        }
      });
    });
  }

  onEdit(pago: Pago) {
    // Mostrar
    this.router.navigate(['/newPago']);
    this.pagosService.selectedPago = Object.assign({}, pago);
  }

  onDelete($key: string) {
    if (confirm('Seguro que desea eliminar el cliente')) {
      this.pagosService.deletePago($key);
      this.toastrService.success('Operacion exitosa', 'Pago Eliminado');
      this.flasMensaje.show('Pago Eliminado Exitosamente', {cssClass: 'alert-success', timeout: 4000});
    }
  }

  showImg(e: any) {
    // console.log(e);
    // console.log(e.srcElement.currentSrc);
    this.urlImagen = e.srcElement.currentSrc;
  }
}
