export default class StringUtils {

   /**
   * Valida la fecha de renovacion y asigna un classs para pintar en frontend
   * @param fecha
   */
  static validarFechaAddClass(fecha: any, hoy: Date) {
    let resp = 'table-success';

    const fechaS = fecha.split('-');

    const f = new Date();
    f.setDate(fechaS[0]); // Dia
    f.setMonth(fechaS[1] - 1); // Mes
    f.setFullYear(fechaS[2]);

    // console.log('this.hoy: ', hoy);
    // console.log('f: ', f);

    if (hoy > f) {
      resp = 'table-danger';
    }
    return resp;
  }
}
