import { Injectable } from '@angular/core';

import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { Cliente } from 'src/app/models/cliente';
import { Observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  public clienteList: AngularFireList<any>;
  selectedCliente: Cliente = new Cliente();

  constructor(
    private firebase: AngularFireDatabase
  ) { }

  getClientes() {
    return this.clienteList = this.firebase.list('cliente');
  }

  insertClientes(cliente: Cliente) {
    this.clienteList.push({
      rut: cliente.rut,
      nombre: cliente.nombre,
      apellidos: cliente.apellidos,
      telefono: cliente.telefono,
      email: cliente.email,
      pagina: cliente.pagina,
      estado: cliente.estado,
      fechaRenovacion: cliente.fechaRenovacion
    });
  }

  updateCliente(cliente: Cliente) {
    this.clienteList.update(cliente.$key, {
      rut: cliente.rut,
      nombre: cliente.nombre,
      apellidos: cliente.apellidos,
      telefono: cliente.telefono,
      email: cliente.email,
      pagina: cliente.pagina,
      estado: cliente.estado,
      fechaRenovacion: cliente.fechaRenovacion
    });
  }

  deleteCliente($key: string) {
    this.clienteList.remove($key);
  }
}
