import { Component, OnInit } from '@angular/core';
import { ClienteService } from 'src/app/servicios/cliente/cliente.service';
import { NgForm } from '@angular/forms';
import { Cliente } from 'src/app/models/cliente';
import { ToastrService } from 'ngx-toastr';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Router } from '@angular/router';
import { BsDatepickerConfig, BsLocaleService} from 'ngx-bootstrap/datepicker';
import { listLocales } from 'ngx-bootstrap/chronos';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.css']
})
export class ClienteComponent implements OnInit {
  // pipe = new DatePipe('en-US');
  datePipe = new DatePipe('en-US');

  // Calendario theme-default, theme-green, theme-blue, theme-dark-blue, theme-red, theme-orange
  colorTheme = 'theme-dark-blue';
  locale = 'es';
  locales = listLocales();
  bsConfig: Partial<BsDatepickerConfig>;
  myDateValue: Date;

  // Mask
  // public maskRut = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public maskRut = [ /[1-9]/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /[0-9kK]/];
  public maskMovil = ['+', /\d/, /\d/, ' ', /[9]/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];

  constructor(
    private localeService: BsLocaleService,
    public clienteService: ClienteService,
    private toastrService: ToastrService,
    public flasMensaje: FlashMessagesService,
    public router: Router
  ) { }

  ngOnInit() {
    // Configuracion color calendario
    this.localeService.use(this.locale);
    // this.myDateValue = new Date();
    this.getFecha();
    this.bsConfig = Object.assign({}, {
      containerClass: this.colorTheme,
      dateInputFormat: 'DD-MM-YYYY'
      // customTodayClass: 'custom-today-class'
     });

    this.clienteService.getClientes();
    this.resetForm();
  }

  getFecha() {
    if (null != this.clienteService.selectedCliente
      && null == this.clienteService.selectedCliente.fechaRenovacion) {
      this.clienteService.selectedCliente.fechaRenovacion = this.datePipe.transform(new Date(), 'dd-MM-yyyy');
    }
  }

  onSubmit(clienteForm: NgForm) {
    clienteForm.value.fechaRenovacion = this.datePipe.transform(clienteForm.value.fechaRenovacion, 'dd-MM-yyyy');
    // this.setFecha(clienteForm);
    if (clienteForm.value.$key == null) {
      this.clienteService.insertClientes(clienteForm.value);
      // this.toastrService.success('Operacion exitosa', 'Cliente Registrado');
      this.toastrService.success('This is success toast.', 'Success!');
      this.flasMensaje.show('Operacion exitosa', {cssClass: 'alert-success', timeout: 4000});
    } else {
      this.clienteService.updateCliente(clienteForm.value);
      // this.toastrService.success('Operacion exitosa', 'Cliente Modificado');
      this.toastrService.success('This is success toast.', 'Success!');
      this.flasMensaje.show('Operacion exitosa', {cssClass: 'alert-success', timeout: 4000});
    }
    this.resetForm(clienteForm);
    this.router.navigate(['/clientes']);
  }

  resetForm(clienteForm?: NgForm) {
    if (clienteForm != null) {
      clienteForm.reset();
      this.clienteService.selectedCliente =  new Cliente();
    }
  }

  cleanForm(clienteForm?: NgForm) {
    this.resetForm(clienteForm);
    this.router.navigate(['/clientes']);
  }

}
