import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../servicios/auth.service';
import { Router } from '@angular/router';

import { FlashMessagesModule, FlashMessagesService } from 'angular2-flash-messages';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

public email: string;
public password: string;

  constructor(
    public authService: AuthService,
    public router: Router,
    public flasMensaje: FlashMessagesService
  ) { }

  ngOnInit() {
  }

  onSbmitLogin() {
    this.authService.loginEmail(this.email, this.password)
    .then( (res) => {
      this.flasMensaje.show('Bienvenido a MC', {cssClass: 'alert-success', timeout: 4000});
      this.router.navigate(['/privado']);
    }).catch((err) => {
      console.log(err);
      this.flasMensaje.show(err, {cssClass: 'alert-danger', timeout: 4000});
      this.router.navigate(['/login']);
    });
  }

}
