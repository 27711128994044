import { NgModule, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Routes, RouterModule} from '@angular/router';
import { LoginComponent } from './componentes/login/login.component';
import { PrivatePageComponent } from './componentes/private-page/private-page.component';
// import { ClienteDetalleComponent } from './componentes/cliente-detalle/cliente-detalle.component';
// import { ClienteListaComponent } from './componentes/cliente-lista/cliente-lista.component';
import { NotFoundPageComponent } from './componentes/not-found-page/not-found-page.component';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { ClientesComponent } from './componentes/clientes/clientes.component';
import { ClientesListaComponent } from './componentes/clientes/clientes-lista/clientes-lista.component';
import { ClienteComponent } from './componentes/clientes/cliente/cliente.component';
import { ListaPagosComponent } from './componentes/pagos/lista-pagos/lista-pagos.component';
import { PagosFormComponent } from './componentes/pagos/pagos-form/pagos-form.component';

const routes: Routes = [
  {path: '', component: LoginComponent},
  {path: 'login', component: LoginComponent},
  {path: 'privado', component: PrivatePageComponent, canActivate: [AuthGuard]},
  // {path: 'cliente-detalle', component: ClienteDetalleComponent, canActivate: [AuthGuard]},
  // {path: 'cliente-lista', component: ClienteListaComponent, canActivate: [AuthGuard]},
  {path: 'clientes', component: ClientesListaComponent, canActivate: [AuthGuard]},
  {path: 'newClientes', component: ClienteComponent, canActivate: [AuthGuard]},
  {path: 'editCliente', component: ClienteComponent, canActivate: [AuthGuard]},
  {path: 'pagos', component: ListaPagosComponent, canActivate: [AuthGuard]},
  {path: 'pagosCliente/:rutCliente', component: ListaPagosComponent, canActivate: [AuthGuard]},
  {path: 'newPago', component: PagosFormComponent, canActivate: [AuthGuard]},
  {path: 'newPago/:rutCliente', component: PagosFormComponent, canActivate: [AuthGuard]},
  {path: '**', component: NotFoundPageComponent}
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      { useHash: true }
      // { enableTracing: true } // <-- Add this to debug routes
      )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
