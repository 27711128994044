export class Pago {
  $key: string;
  rutCliente: string;
  nombreCliente: string;
  fechaInicio: string;
  fechaExpiracion: string;
  fechaExpiClass: string;
  fechaProximoPago: string;
  fechaProxiClass: string;
  comprobante: string;
  estadoPago: string;
  montoPago: string;
}
