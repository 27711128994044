import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pagos-id-cliente',
  templateUrl: './pagos-id-cliente.component.html',
  styleUrls: ['./pagos-id-cliente.component.css']
})
export class PagosIdClienteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
